.service-info {
    border-bottom: 1px solid #363636;
    padding-bottom: 12px;
    padding-top: 12px;

    .service-info-category {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%;
        margin-bottom: 10px;

        &.order-category-1 {
            color: var(--order-header-category-color-1);
        }
        &.order-category-2 {
            color: var(--order-header-category-color-2);
        }
        &.order-category-3 {
            color: var(--order-header-category-color-3);
        }

        .service-info-count {
            color: #525252;
            margin-left: 10px;
        }
    }

    .service-info-type {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%;
        color: #8B8B8B;
        margin-right: 24px;

        &.disabled {
            color: #525252;
        }
    }
}