.add-details-modal {
    .modal-content {
        padding: 4cqh;
        border-radius: 14px;
        background-color: #262626;
        color: #CCC;

        .modal-header {
            padding: 0;
            border-bottom: 0;
            margin-bottom: 20px;

            .modal-title {
                line-height: 130%;
                font-size: 17px;
                font-weight: 600;
            }

            .btn-close {
                filter: invert(100%) sepia(3%) saturate(431%) hue-rotate(333deg) brightness(113%) contrast(81%);
                opacity: 1;
            }
        }

        .modal-body {
            padding: 0;

            .label-required {
                font-size: 13px;
                line-height: 130%;
                color: #525252;
            }

            .form-label {
                color: #8B8B8B;
                font-size: 13px;
                font-weight: 400;
                line-height: 106%;
                margin-bottom: 4px;
            }

            .form-select, .form-control {
                color: #CCC;
                font-size: 15px;
                font-weight: 400;
                line-height: 106%;
                border-radius: 8px;
                border: 1px solid #363636;
                background-color: #262626;
                padding: 8px 16px 10px 16px;

                &:disabled {
                    background-color: #2C2C2C;
                }

                &::placeholder {
                    color: #3E3E3E;
                }
            }

            .tooth-selected-block {
                border-radius: 8px;
                border: 1px solid #363636;
                padding: 4px 10px 6px 10px;
                font-size: 15px;
                line-height: 106%;
                color: #CCC;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;

                .delete-detail-tooth {
                    filter: invert(36%) sepia(0%) saturate(541%) hue-rotate(144deg) brightness(84%) contrast(93%);
                    margin-left: 4px;
                }
            }
        }
    }
}