.service-card {
    background-color: #262626;
    border-radius: 16px;

    &-title {
        padding: 20px;
        background-color: #2F2F2F;
        border-radius: 16px;

        img {
            border-radius: 12px;
        }

        .owner-about {
            .owner-name {
                color: #CCC;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .owner-rating {
                color: #8B8B8B;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 106%; 
            }
        }
    }

    &-description {
        padding: 10px 20px 20px 20px;        

        .order-category {
            padding: 5px 14px 6px 14px;;
            border-radius: 50px;
            font-size: 13px;
            margin-bottom: 10px;

            &.order-category-1 {
                background-color: var(--order-header-category-bg-1);
                color: var(--order-header-category-color-1);
            }
            &.order-category-2 {
                background-color: var(--order-header-category-bg-2);
                color: var(--order-header-category-color-2);
            }
            &.order-category-3 {
                background-color: var(--order-header-category-bg-3);
                color: var(--order-header-category-color-3);
            }
        }

        &-title {
            color: #CCC;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%; 
            margin-bottom: 10px;
        }

        &-text {
            font-size: 15px;
            margin-bottom: 20px;
        }

        .additional-service {
            padding: 10px 0;
            border-bottom: 1px solid #363636;
            .text-label {
                line-height: 147%;
                font-size: 13px;
                color: #8B8B8B !important;
            }

            &-price {
                color: #CCC;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
            }
        }
    }
}

.service-pay-info {
    border-radius: 16px;
    background-color: #3385FF;
    padding: 20px;
    font-size: 15px;
    color: #FFF;

    &-value {
        font-weight: 600;
    }
}