.main-page {
    .service-card {
        cursor: pointer;
        background-color: #2F2F2F;
        &:hover {
            .service-info-block {
                background-color: #313131;
            }
        }
        .btn-block {
            background-color: #292427;

            .btn {
                background: #2F2F2F;
            }
        }

        .order-header-type-block{
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            font-size: 11px;
            line-height: 100%;
            margin-top: -3px;
            margin-left: -1px;
            vertical-align: text-top;

            .order-category {
                padding: 3px 8px;
                border-radius: 10px 0 10px 0;

                &.order-category-1 {
                    background-color: var(--order-header-category-bg-1);
                    color: var(--order-header-category-color-1);
                }
                &.order-category-2 {
                    background-color: var(--order-header-category-bg-2);
                    color: var(--order-header-category-color-2);
                }
                &.order-category-3 {
                    background-color: var(--order-header-category-bg-3);
                    color: var(--order-header-category-color-3);
                }
            }
            .order-type {
                padding: 3px 8px;
            }
        }

        .service-type-block {
            padding: 0px 20px 10px 20px;

            .service-type-name {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 106%;
                color: #8B8B8B;
            }
        }

        .service-info-block {
            padding: 10px 20px 20px 20px;
            background-color: #262626;
            color: #CCC;
            border-radius: 0px 0px 10px 10px;

            .service-description {
                font-size: 15px;
                padding-bottom: 10px;
            }
    
            .service-price {
                font-size: 19px;
                font-style: normal;
                font-weight: 600;
                line-height: 105%;
                border-bottom: 1px solid #363636;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            .service-owner {
                .owner-avatar {
                    img {
                        border-radius: 10px;
                    }
                }
                .owner-about {
                    .owner-name {
                        color: #8B8B8B;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 106%;
                    }

                    .owner-rating {
                        color: #525252;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 106%; 
                    }
                }
            }
        }
    }
}