:root[data-theme="light"] {
    --background-color: #F8F8F8;
  }

:root[data-theme="dark"] {
    --background-color: #121212;
}

.create-order-modal {

    .owner-avatar {
        img {
            border-radius: 10px;
        }
    }
    
    .modal-content {
        padding: 30px;
        border-radius: 16px;
        background-color: #1E1E1E;
        color: #CCC;

        .type-order-block{
            background-color: var(--order-header-type);
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            font-size: 13px;
            line-height: 100%;

            .order-category {
                padding: 2px 15px 3px 15px;
                border-radius: 10px 0 10px 0;

                &.order-category-1 {
                    background-color: var(--order-header-category-bg-1);
                    color: var(--order-header-category-color-1);
                }
                &.order-category-2 {
                    background-color: var(--order-header-category-bg-2);
                    color: var(--order-header-category-color-2);
                }
                &.order-category-3 {
                    background-color: var(--order-header-category-bg-3);
                    color: var(--order-header-category-color-3);
                }
            }
            .order-type {
                padding: 2px 15px 3px 15px;
            }
        }

        .modal-header {
            padding: 0;
            border-bottom: 0;
            margin-bottom: 22px;

            .modal-title {
                line-height: 105%;
                font-size: 28px;
                font-weight: 600;
            }

            .btn-close {
                filter: invert(100%) sepia(3%) saturate(431%) hue-rotate(333deg) brightness(113%) contrast(81%);
                opacity: 1;
            }
        }

        .modal-body {
            padding: 0;

            .modal-card {
                background-color: #262626;
                border-radius: 12px;
                padding: 20px;

                .card-title {
                    color: #CCC;
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 130%;
                    margin-bottom: 16px;
                }

                .col-form-label {
                    color: #8B8B8B;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 106%;
                }

                .form-select, .form-control {
                    color: #CCC;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 106%;
                    border-radius: 8px;
                    border: 1px solid #363636;
                    background-color: #262626;
                    padding: 8px 16px 10px 16px;

                    &:disabled {
                        background-color: #2C2C2C;
                    }

                    &::placeholder {
                        color: #3E3E3E;
                    }
                }

                .form-check {
                    font-size: 15px;
                    font-weight: 400;
                    color: #CCC;
                    
                    .text-muted {
                        color: #525252 !important;
                        font-size: 13px;
                    }
                }

                .text-description {
                    color: #525252;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 106%;
                }

                .details-block {
                    border: 1px solid #363636;
                    border-radius: 8px;
                    margin-bottom: 10px;

                    .teeth-block {
                        margin: 16px 16px 5px 16px;
                        border-bottom: 1px solid #363636;

                        .tooth-selected-block {
                            border-radius: 8px;
                            border: 1px solid #363636;
                            padding: 4px 10px 6px 10px;
                            font-size: 15px;
                            line-height: 106%;
                            color: #CCC;
                            display: inline-block;
                            margin-right: 10px;
                            margin-bottom: 10px;

                            .delete-detail-tooth {
                                filter: invert(36%) sepia(0%) saturate(541%) hue-rotate(144deg) brightness(84%) contrast(93%);
                                margin-left: 4px;
                            }
                        }
                    }

                    .details-comments {
                        padding: 5px 16px 10px 16px;

                        .details-text {
                            color: #8B8B8B;
                            font-size: 15px;
                        }
                    }

                    .details-buttons {
                        border-top: 1px solid #363636;
                        
                        button {
                            color: #525252;
                            font-size: 13px;
                            padding: 8px 16px;

                            img {
                                filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(322deg) brightness(93%) contrast(91%);
                                margin-right: 6px;
                                vertical-align: text-top;
                                transition: filter .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                            }

                            &:first-child {
                                border-right: 1px solid #363636;
                                border-radius: 0 0 0 8px;
                            }

                            &:last-child {
                                border-radius: 0 0 8px 0;
                            }

                            &:hover {
                                background-color: #363636;
                                color: #CCC;

                                img {
                                    filter: invert(87%) sepia(3%) saturate(25%) hue-rotate(332deg) brightness(90%) contrast(106%);
                                    transition: filter .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                                }
                            }
                        }
                    }
                }

                .cost-total {
                    color: #CCC;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;

                    .cost-value {
                        font-weight: 600;
                    }
                }

                .owner-name {
                    color: #CCC;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%;
                    text-decoration: none;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .status-block {
                    border-top: 1px solid #363636;

                    .status-panel {
                        position: relative;
                        z-index: 1;

                        /* Граница сверху */
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 10px; /* Отступ сверху */
                            width: 2px; /* Толщина границы */
                            height: calc(50% - 10px); /* Высота границы (половина высоты блока минус отступ) */
                            background-color: #363636; /* Цвет границы */
                        }

                        /* Граница снизу */
                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 10px; /* Отступ снизу */
                            width: 2px; /* Толщина границы */
                            height: calc(50% - 10px); /* Высота границы (половина высоты блока минус отступ) */
                            background-color: #363636; /* Цвет границы */
                        }

                        .status-item {
                            position: relative;
                            padding-left: 16px;
                            padding-bottom: 16px;
                            z-index: 2;
                            font-size: 15px;
                            color: #8B8B8B;

                            &::before {
                                content: '';
                                width: .6em;
                                height: .6em;
                                border-radius: 1em;
                                background-color: #8B8B8B;
                                position: absolute;
                                left: -4px;
                                top: 7px;
                            }

                            &.active {
                                color: #3385FF;

                                &::before {
                                    background-color: #3385FF;
                                }

                                .status-date {
                                    background-color: #10213C;
                                    display: inline-block;
                                    padding: 4px 10px;
                                    border-radius: 6px;
                                    font-size: 13px;
                                }
                            }

                            &:last-child {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }

            .accordion-header {
                cursor: pointer;

                &.opened {
                    .accordion-arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            .accordion-arrow {
                transition: transform .15s ease-in-out;
                filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(117deg) brightness(106%) contrast(98%);
            }
        }
    }
}

.tooth-panel {
    .tooth-numb {
        border: 1px solid #363636;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
    }
}

.danger-label {
    color: #AC1818;
    font-size: 11px;
    font-weight: 400;
    line-height: 106%; 
}

.accept-comment {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%; 
    color: #8B8B8B;
    padding: 8px 15px;
}

.danger-border {
    border: 1px solid #AC1818;
}

.accepted-block-text {
    color: #3385FF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%;     
}