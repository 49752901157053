@import "colors";
@import "panel_state.scss";
@import "workzone_plane";
@import 'modal_delete';
@import 'modal_create_order';
@import 'modal_add_details';
@import 'accordion-custom';
@import 'profile_modal';
@import 'portfolio';
@import 'service';
@import 'main_page';
@import 'service_page';
@import 'profile_info';

:root[data-theme="light"] {
    --background-color: #F8F8F8;
    --input-form-control-color: #C6C6C6;
    --input-form-control-focus-color: #202020;
    --input-form-control-border-color: #E7E7E7;
    --footer-background-color: #FFF;
    --navbar-background-color: #FFF;
    --card-simple-background-color: #FFFFFF;
    --navbar-color: #202020;
    --navbar-dropdownmenu-background-color: #FFFFFF;
    --navbar-dropdownmenu-border-color:#E7E7E7;
    --navbar-dropdownmenu-dropdownitem-color: #868686;
    --navbar-dropdownmenu-dropdownitem-hover-background-color: #9DC5FF;
    --btn-outline-secondary-border-color: #F1F1F1;
    --switcher-sun: invert(76%) sepia(61%) saturate(1138%) hue-rotate(330deg) brightness(100%) contrast(99%);
    --switcher-moon: invert(100%) sepia(0%) saturate(3345%) hue-rotate(262deg) brightness(80%) contrast(119%);
    --scrollbar-color: #CECECE;
  }

:root[data-theme="dark"] {
    --background-color: #121212;
    --input-form-control-color: #3E3E3E;
    --input-form-control-focus-color: #CCCCCC;
    --input-form-control-border-color: #363636;
    --footer-background-color: #262626;
    --navbar-background-color: #262626;
    --card-simple-background-color: #262626;
    --navbar-color: #CCCCCC;
    --navbar-dropdownmenu-background-color: #262626;
    --navbar-dropdownmenu-border-color:#363636;
    --navbar-dropdownmenu-dropdownitem-color: #8B8B8B;
    --navbar-dropdownmenu-dropdownitem-hover-background-color: #10213C;
    --btn-outline-secondary-border-color: #363636;
    --switcher-sun: invert(27%) sepia(0%) saturate(1325%) hue-rotate(186deg) brightness(97%) contrast(75%);
    --switcher-moon: invert(45%) sepia(93%) saturate(3027%) hue-rotate(202deg) brightness(102%) contrast(100%);
    --scrollbar-color: #464646;
}  

::-webkit-scrollbar {
    width: 4px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-clip: padding-box;
    background-color: var(--scrollbar-color);
}

body {
    color: var(--navbar-dropdownmenu-dropdownitem-color);
    background-color: var(--background-color);
    font-family: 'Roboto';
}

h1, h2, h3, h4, h5 {
    color: $color-gray-light;
}

.title-font-size {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.header-block-font-size {
    font-size: 13px !important;
    font-weight: 400 !important;
}

.simple-text-font-size {
    font-size: 11px !important;
    font-weight: 400 !important;
}

.text-label-color {
    color: #8B8B8B !important;
}

.text-value-color {
    color: #CCC !important;
}

.cursor-pointer {
    cursor: pointer;
}

.font-size-13 {
    font-size: 13px;
}

.text-gray {
    color: #525252;
}

.validation-summary-errors {
    li {
        list-style-type: none;
    }
}

.form-label {
    font-weight: 500;
}

input.form-control {
    border-radius: 10px;
    background-color: transparent;
    border-color: var(--input-form-control-border-color);
    color: var(--input-form-control-color);

    &:focus {
        background-color: transparent;
        color: var(--input-form-control-focus-color);
    }
}

input.form-check-input {
    background-color: transparent;
    border-color: #3385FF;
}

.input-error-message {
    font-size: 13px;
}

.profile-avatar {
    object-fit: cover;
}

.navbar {
    color: var(--navbar-color);
    background-color: var(--navbar-background-color);

    padding-top: 10px;
    padding-bottom: 10px;

    .inside-block {
        padding-left: 40px;
        padding-right: 40px;
    }

    .nav-menu {
        .nav-item {
            padding-left: 50px;
        }
    }

    .nav-item {
        font-size: 17px;
        font-weight: 600;

        .nav-link {
            color: var(--navbar-color) !important;

            &:hover {
                color: #3385FF !important;
            }
        }
    }

    .dropdown-menu {
        border-radius: 16px;
        padding: 4px;

        background-color: var(--navbar-dropdownmenu-background-color);
        border-color: var(--navbar-dropdownmenu-border-color);

        .dropdown-item {
            padding: 8px 20px 10px 20px;
            border-radius: 12px;
            color: var(--navbar-dropdownmenu-dropdownitem-color);
            overflow: hidden;

            &:hover {
                background-color: var(--navbar-dropdownmenu-dropdownitem-hover-background-color);
                color: #fff;
            
                &:after {
                    content: '';
                    height: 100%; //You can change this if you want smaller/bigger borders
                    width: 2px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #3385FF; // The color of your border
                }
            }
        }
    }
}

.text-label {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%;
    color: #8B8B8B;

    &.form-check {
        color: #525252 !important;
    }
}

.text-value {
    color: #CCC;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%;
}

.show-hide-password {
    position: absolute;
    top: 42px;
    right: 15px;
    cursor: pointer;
}

.card-simple {
    padding: 25px;
    border-radius: 16px;
    background-color: var(--card-simple-background-color);
}

.text-link {
    color: #3385FF;
    text-decoration: none;
}

.btn {
    font-size: 17px;
    border-radius: 10px;
    padding: 7px 30px 9px 30px;

    &.btn-sm {
        font-size: 15px;
        padding: 5px 15px 7px 15px;
    }

    &.btn-icon-noborder {
        padding: 4px 7px;
    }

    &.btn-icon {
        border-radius: 10px;
        padding: 12px;
        line-height: 50%;
    }

    &.btn-primary {
        background-color: #3385FF;

        &:hover {
            background-color: #5BADFF;
        }
    }

    &.btn-secondary {
        background-color: var(--navbar-dropdownmenu-border-color);
        border-radius: 6px;
        border: none;
        padding: 5px 15px 7px 15px;
        font-size: 15px;
        color: var(--navbar-color);
        font-weight: 400;
        line-height: 106%;

        &.active, &:hover {
            background-color: #3385FF;
            color: #fff ;
        }
    }

    &.btn-outline-secondary {
        border: 1px solid var(--btn-outline-secondary-border-color);
        color: var(--navbar-dropdownmenu-dropdownitem-color);
    
        &.active, &:hover {
            border-color: #3385FF;
            background-color: transparent;
            color: #3385FF !important;
            filter: invert(49%) sepia(77%) saturate(4208%) hue-rotate(204deg) brightness(105%) contrast(101%);
        }
    }
    &.btn-outline-primary {
        img {
            filter: invert(30%) sepia(60%) saturate(4344%) hue-rotate(210deg) brightness(101%) contrast(98%);
        }        
        
        &:hover {
            img {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(105%) contrast(103%);
            }
        }
    }

    &.btn-sm-only-text {
        font-size: 13px;
        color: #525252;

        &:hover {
            color: #8B8B8B;
            text-decoration: underline;
        }
    }

    &.btn-only-text-primary {
        font-size: 15px;
        color: #3385FF;

        &:hover {
            color: #fff;

            img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(49deg) brightness(103%) contrast(103%);
            }
        }

        img {
            filter: invert(49%) sepia(24%) saturate(7496%) hue-rotate(200deg) brightness(99%) contrast(104%);
        }
    }

    &.btn-skill {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%;
        color: #8B8B8B;
        border-radius: 8px;
        background-color: #2F2F2F;
        padding: 4px 10px 6px 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        &:hover {
            background-color: #3385FF;
            color: #fff;
        }
    }
}

.skill-block {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 106%;
    color: #8B8B8B;
    border-radius: 8px;
    background-color: #2F2F2F;
    padding: 4px 10px 6px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.auth-container {
    height: calc(100vh - 220px);
}

.theme-switcher {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 50px;

    .switcher-sun {
        filter: var(--switcher-sun);
    }

    .switcher-moon {
        filter: var(--switcher-moon);
    }

    .form-switch {
        margin-bottom: 6px;

        .form-check-input {
            height: 20px;
            width: 36px;
            background-color: #FDA837;
            --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e');
            border: none;

            &:checked {
                background-color: #3385FF;
            }
        }
    }
}

.footer {
    padding-top: 87px;
    padding-bottom: 93px;
    background-color: var(--footer-background-color);

    .footer-main-link {
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 24px;
    }
}

.text-color-gray {
    color: $color-gray-light !important;
}

.dropdown-profile-icon {
    & > .dropdown-toggle.nav-link {
        padding:0 !important
    }
}

.lk-menu {
    background-color: #262626;
    border: 1px solid #000;
    border-radius: 16px;

    .lk-menu-header {
        border-radius: 16px 16px 0 0;
        background-color: #3385FF;
        color: #fff;
        padding: 10px 0 10px 0;
        font-size: 22px;
        font-weight: 600;
    }

    .nav {
        margin: 10px 3px;

        .nav-link {
            padding: 15px 25px 15px 25px;
            border-radius: 12px;
            color: var(--navbar-color);
            overflow: hidden;
            position: relative;
            font-size: 20px;

            &.active, &:hover {
                background-color: var(--navbar-dropdownmenu-dropdownitem-hover-background-color);
                color: #fff;
            
                &:after {
                    content: '';
                    height: 100%; //You can change this if you want smaller/bigger borders
                    width: 2px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #3385FF; // The color of your border
                }
            }

            &.disabled {
                color: rgb(69, 69, 69)
            }

            img {
                filter: invert(83%) sepia(0%) saturate(2%) hue-rotate(13deg) brightness(103%) contrast(84%);
            }
        }
    }
}

.raiting-header {
    color: #ccc;
    font-weight: 500;
    font-size: 20px;
}

.block-label-value {
    margin-bottom: 10px;
    .label {
        display: inline-block;
        width: 180px;
    }

    .value {
        color: #ccc;
    }
}

.btn-sm-dental {
    border-radius: 50px;
    padding: 1px 15px 3px 15px !important;
    font-size: 15px;
}

.modal-content {
    background-color: var(--card-simple-background-color);
}

.dental-tooltip {
    margin-bottom: -3px;
    .tooltip-inner{
        background-color: #3385FF;
    }

    .tooltip-arrow {
        display: none;
    }
}

.dropdown-input-menu {
    border-radius: 8px;
    border: 1px solid #363636;
    background-color: #262626;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 4px;

    .dropdown-patients-block {
        max-height: 200px;
        overflow-y: scroll;
    }

    .dropdown-item {
        font-size: 15px;
        font-weight: 400;
        line-height: 106%;
        color: #8B8B8B;
        padding: 8px 10px 9px 10px;
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        &:hover {
            background-color: var(--navbar-dropdownmenu-dropdownitem-hover-background-color);
            color: #fff;

            &:after {
                content: '';
                height: 100%; //You can change this if you want smaller/bigger borders
                width: 2px;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #3385FF; // The color of your border
            }
        }

        &.patient-add-btn {
            border-top: 1px solid #363636;
            color: #3385FF;

            img {
                filter: invert(36%) sepia(66%) saturate(1661%) hue-rotate(202deg) brightness(109%) contrast(101%);
                margin-right: 10px;
            }
        }
    }
}

.simple-file-upload {
    border: 1px dashed #363636;
    padding: 16px 30px;
    border-radius: 10px;

    .text-about-file {
        color: #525252;
        font-size: 13px;
        font-weight: 400;
        line-height: 106%;
    }

    .text-download {
        color: #8B8B8B;
        font-size: 15px;
        font-weight: 400;
        line-height: 106%;
        img {
            filter: invert(48%) sepia(97%) saturate(14%) hue-rotate(22deg) brightness(95%) contrast(94%);
        }
    }
}

.simple-card {
    background: #262626;
    border-radius: 16px;
    padding: 30px;
}

.simple-table {
    background-color: #262626;
    border-radius: 8px;
    overflow: hidden;
    thead {
        tr {
            border-radius: 8px;
        }
        th {
            background-color: #2F2F2F;
            color: #CCC;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            border-bottom: 0px solid #363636;
        }
    }
    tbody {
        tr {
            td {
                background-color: #262626;
                color: #8B8B8B;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                border-color: #3385FF;
            }
        }
    }
}