:root[data-theme="light"] {
    --modal-delete-close-icon: invert(92%) sepia(12%) saturate(15%) hue-rotate(334deg) brightness(92%) contrast(98%);
    --panel-state-header-title: #202020;
    --modal-body-icon: invert(0%) sepia(43%) saturate(231%) hue-rotate(317deg) brightness(95%) contrast(75%);
    --modal-text: #868686;
  }

:root[data-theme="dark"] {
    --modal-delete-close-icon: invert(80%) sepia(50%) saturate(0%) hue-rotate(136deg) brightness(103%) contrast(99%);
    --panel-state-header-title: #CCC;
    --modal-body-icon: invert(90%) sepia(4%) saturate(0%) hue-rotate(144deg) brightness(97%) contrast(80%);
    --modal-text: #8B8B8B;
}  

.modal-delete {
    .modal-content {
        border-radius: 24px;

        .modal-header {
            border-bottom: none;
            padding: 30px 30px 0 30px;

            .btn-close {
                filter: var(--modal-delete-close-icon);
                opacity: 1;
            }
        }

        .modal-body {
            font-size: 17px;
            font-weight: 600;
            line-height: 130%;
            color: var(--panel-state-header-title);
            padding-bottom: 19px;
            padding-top: 10px;

            img {
                filter: var(--modal-body-icon);
                margin-right: 16px;
                vertical-align: bottom;
            }

            .text-modal {
                font-size: 17px;
                font-weight: 400;
                line-height: 130%;
                color: var(--modal-text);
                padding-top: 19px;
                margin-bottom: 41px;
                padding-left: 60px;
                padding-right: 60px;
            }
        }

        .modal-footer {
            border-top: none;
            padding-top: 0;
            padding-bottom: 60px;
        }
    }
}