.accordion-custom {
    .accordion-header {
        background-color: #2F2F2F;
        border-radius: 8px;
        padding: 10px 20px;
        line-height: 106%;
        font-size: 15px;

        img {
            filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(117deg) brightness(106%) contrast(98%);
        }

        &.opened {
            background-color: #10213C;
            border-radius: 10px 10px 0px 0px;

            img {
                rotate: 180deg;
            }
        }
    }

    .accordion-body {
        .accordion-dropzone {
            border: 1px dashed #3C3C3C;
            border-radius: 10px;
            padding: 16px 30px;

            .text-about-file {
                color: #525252;
                font-size: 13px;
                line-height: 106%;
            }

            .text-download {
                color: #8B8B8B;
                font-size: 15px;
                line-height: 106%;
                img {
                    filter: invert(48%) sepia(97%) saturate(14%) hue-rotate(22deg) brightness(95%) contrast(94%);
                }
            }
        }

        .order-files-block {
            .order-files-title {
                font-size: 15px;
                line-height: 106%;
            }

            .order-file{
                font-size: 13px;
                line-height: 100%;
                color: #8B8B8B;
            }

            .order-file-action-btn {
                color: #8B8B8B;
                font-size: 11px;
                padding: 0 10px;
                border-radius: 0;

                &:first-child {
                    border-right: 1px solid #363636;
                }

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}