.portfolio-item {
    background: #2F2F2F;
    border-radius: 10px;

    img {
        border-radius: 10px 10px 0px 0px;
    }

    .portfolio-name {
        padding: 10px 10px 9px 10px;
        color: #CCC;
        font-size: 15px;
        font-weight: 400;
        line-height: 106%;
    }

    button {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px;
        font-weight: 400;
        color: #525252;
        border-top: 1px solid #363636;

        &:first-child {
            border-radius: 0px 0px 0px 10px;
            border-right: 1px solid #363636;
        }

        &:last-child {
            border-radius: 0px 0px 10px 0px;
        }

        &:hover {
            color: #CCC;
            background-color: #464646;

            img {
                filter: invert(96%) sepia(0%) saturate(695%) hue-rotate(122deg) brightness(89%) contrast(83%);
            }
        }

        img {
            filter: invert(27%) sepia(12%) saturate(0%) hue-rotate(181deg) brightness(103%) contrast(82%);
        }
    }
}