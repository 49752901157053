:root[data-theme="light"] {
    --panel-state-bg: #F8F8F8;
    --panel-state-header: #FFF;
    --panel-state-header-title: #202020;
    --panel-state-order-label: #B6B6B6;
    --order-header-type: #F1F1F1;
    --order-header-category-bg-1: #DC8EFF;
    --order-header-category-color-1: #850CBA;
    --order-header-category-bg-2: #83E6F8;
    --order-header-category-color-2: #047B8F;
    --order-header-category-bg-3: #B7A5FF;
    --order-header-category-color-3: #5D42C9;
    --icon-header: invert(87%) sepia(11%) saturate(21%) hue-rotate(334deg) brightness(89%) contrast(88%);
    --icon-default: invert(53%) sepia(18%) saturate(19%) hue-rotate(330deg) brightness(99%) contrast(101%);
    --icon-header-pinned: invert(90%) sepia(5%) saturate(0%) hue-rotate(358deg) brightness(95%) contrast(100%);
    --card-order-bg-hover: #EDEDED;
    --icon-chevron: invert(92%) sepia(5%) saturate(12%) hue-rotate(6deg) brightness(90%) contrast(93%);
    --btn-menu-arrow-left: #CECECE;
    --btn-menu-arrow-left-icon: invert(6%) sepia(13%) saturate(170%) hue-rotate(314deg) brightness(92%) contrast(84%);
    --btn-menu-item-selected: #9DC5FF;
    --btn-menu-item-selected-svg: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    --btn-menu-item-border: #f4f4f4;
    --btn-filter-reset-bg: #EEE;
    --btn-filter-reset-color: #868686;
  }

:root[data-theme="dark"] {
    --panel-state-bg: #121212;
    --panel-state-header: #262626;
    --panel-state-header-title: #CCC;
    --panel-state-order-label: #525252;
    --order-header-type: #2f2f2f;
    --order-header-category-bg-1: #2F1839;
    --order-header-category-color-1: #BF52F1;
    --order-header-category-bg-2: #152B2F;
    --order-header-category-color-2: #29D1EE;
    --order-header-category-bg-3: #34295E;
    --order-header-category-color-3: #A282FF;
    --icon-header: invert(31%) sepia(4%) saturate(15%) hue-rotate(332deg) brightness(89%) contrast(82%);
    --icon-default: invert(52%) sepia(1%) saturate(1%) hue-rotate(315deg) brightness(102%) contrast(86%);
    --icon-header-pinned: invert(100%) sepia(1%) saturate(4542%) hue-rotate(320deg) brightness(132%) contrast(81%);
    --card-order-bg-hover: #363636;
    --icon-chevron: invert(30%) sepia(0%) saturate(0%) hue-rotate(142deg) brightness(100%) contrast(112%);
    --btn-menu-arrow-left: #464646;
    --btn-menu-arrow-left-icon: invert(81%) sepia(3%) saturate(37%) hue-rotate(314deg) brightness(105%) contrast(85%);
    --btn-menu-item-selected: #10213C;
    --btn-menu-item-selected-svg: invert(48%) sepia(89%) saturate(3035%) hue-rotate(200deg) brightness(98%) contrast(106%);
    --btn-menu-item-border: #3C3C3C;
    --btn-filter-reset-bg: #2F2F2F;
    --btn-filter-reset-color: #8B8B8B;
}  

.col-panel-state {
    max-width: 306px;
}
.panel-state {
    background-color: var(--panel-state-bg);
    border-radius: 16px;

    .icon-header {
        padding: 4px;
        line-height: 100%;
        border-radius: 8px;
        
        &:hover {
            background-color: var(--order-header-type);
        }

        img {
            filter: var(--icon-header);
        }

        &.pinned {
            img {
                filter: var(--icon-header-pinned);
            }
        }
    }

    &-header {
        background-color: var(--panel-state-header);
        border-radius: 8px;
        padding: 6px 4px 6px 10px;
        margin-bottom: 10px;
        line-height: 106%;

        .header-title {
            color: var(--panel-state-header-title);
            font-size: 15px;
            font-weight: 400;
        }

        .badge-event-count {
            padding: 4px 8px;
            border-radius: 6px;
            border: 1px solid #3385FF;
            font-size: 13px;
            color: #3385FF;
            margin-right: 4px;
            line-height: 106%;
        }

        .btn-menu-col {

            .btn-icon-menu {
                padding: 4px;
                border: 0;
                line-height: 100%;
            }}

            .plane-menu-panel {
                position: absolute;
                background-color: var(--panel-state-header);
                padding: 4px;
                font-size: 13px;
                line-height: 106%;
                z-index: 10;
                border-radius: 8px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
                white-space: nowrap;    
                top: 34px;
                right: -50px;                

                .accordion-collapse.show {
                    border-bottom: 1px solid var(--btn-menu-item-border);
                }

                .plane-menu-item {
                    padding: 8px 10px 9px 10px;
                    border-radius: 6px;

                    &:hover {
                        background-color: var(--order-header-type);
                        cursor: pointer;
                    }

                    &.active {
                        background-color: var(--order-header-type);
                        cursor: default;
                        color: var(--panel-state-header-title);
                        padding: 3px 10px 3px 5px;
                    }

                    &.active-accordion {
                        color: var(--panel-state-header-title);
                        font-weight: 600;
                        .accordion-arrow {
                            transform: rotate(-90deg);
                        }
                    }

                    &.selected {
                        background-color: var(--btn-menu-item-selected);
                        color: #FFF;

                        img {
                            filter: var(--btn-menu-item-selected-svg);
                        }
                    }

                    .accordion-arrow {
                        transform: rotate(90deg);
                    }

                    .left-icon {
                        filter: var(--icon-default);
                        margin-right: 10px;
                    }

                    .right-icon {
                        filter: var(--icon-chevron);
                    }

                    .menu-arrow-left {
                        border-radius: 6px;
                        padding: 4px;
                        margin-right: 16px;
                        line-height: 78%;

                        img {
                            filter: var(--btn-menu-arrow-left-icon);
                        }
                        
                        &:hover {
                            background-color: var(--btn-menu-arrow-left);
                        }
                    }
                }

                .btn-filter-reset {
                    border-radius: 6px;
                    background: var(--btn-filter-reset-bg);
                    padding: 8px 10px 9px 10px;
                    font-size: 11px;
                    color: var(--btn-filter-reset-color);
                    line-height: 100%;

                    &:hover {
                        color: #3385FF;
                    }
                }
            }
    }

    &-body {
        max-height: calc(100vh - 200px);
        overflow-x: hidden;
        overflow-y: scroll;
        scroll-margin-left: 4px;

        .card-order-add {
            background-color: var(--panel-state-header);
            border-radius: 6px;
            padding: 5px 10px 7px 10px;
            line-height: 106%;
            color: #3385FF;
            font-size: 15px;

            &:hover {
                cursor: pointer;
                border: 1px solid #3385FF;
                padding: 4px 9px 6px 9px;
            }
            
            img {
                filter: invert(36%) sepia(66%) saturate(1661%) hue-rotate(202deg) brightness(109%) contrast(101%);
            }
        }

        .card-order {
            background-color: var(--panel-state-header);
            border-radius: 8px;
            margin-bottom: 10px;

            &:hover {
                cursor: pointer;
                background-color: var(--card-order-bg-hover);
            }

            &-header {
                .order-header-type-block{
                    background-color: var(--order-header-type);
                    border-bottom-right-radius: 10px;
                    border-top-left-radius: 10px;
                    font-size: 11px;
                    line-height: 100%;

                    .order-category {
                        padding: 3px 8px;
                        border-radius: 10px 0 10px 0;

                        &.order-category-1 {
                            background-color: var(--order-header-category-bg-1);
                            color: var(--order-header-category-color-1);
                        }
                        &.order-category-2 {
                            background-color: var(--order-header-category-bg-2);
                            color: var(--order-header-category-color-2);
                        }
                        &.order-category-3 {
                            background-color: var(--order-header-category-bg-3);
                            color: var(--order-header-category-color-3);
                        }
                    }
                    .order-type {
                        padding: 3px 8px;
                    }
                }
                .order-identificator {
                    font-size: 10px;
                }

                .header-icons {
                    line-height: 100%;
                }
            }

            &-body {
                padding: 10px;

                .order-full-type {
                    font-size: 15px;
                    font-weight: 400;
                    color: var(--panel-state-header-title);
                    margin-bottom: 6px;
                    line-height: 106%;
                }

                .order-patient-name {
                    font-size: 13px;
                    margin-bottom: 6px;
                }

                .order-label {
                    font-size: 13px;
                    color: var(--panel-state-order-label);
                    line-height: 106%;
                    margin-bottom: 2px;
                }

                .order-value {
                    font-size: 11px;
                    line-height: 106%;
                }

                .badge-order-event {
                    padding: 4px 4px;
                    border-radius: 6px;
                    background: #FEC672;
                    font-size: 13px;
                    color: #202020;
                    line-height: 100%;

                    img {
                        margin-right: 2px;
                        filter: invert(100%) sepia(63%) saturate(3%) hue-rotate(314deg) brightness(86%) contrast(87%);
                    }
                }

                .badge-order-comment{
                    padding: 4px 4px;
                    border-radius: 6px;
                    background: #8BFFB3;
                    font-size: 13px;
                    color: #202020;
                    line-height: 100%;

                    img {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
}

.collapsed {
    background-color: var(--panel-state-header);
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 8px;

    .btn-collapse {
        background-color: #3385FF;
        border-radius: 8px;
        padding: 6px 4px;

        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(101%) contrast(104%);
        }
    }

    .collapsed-header-title {
        font-size: 15px;
        line-height: 106%;
        color: var(--panel-state-header-title);
        padding-left: 10px;
        padding-right: 4px;

        .btn {
            padding: 4px;
            line-height: 77%;
        }

        img {
            filter: invert(30%) sepia(24%) saturate(0%) hue-rotate(291deg) brightness(93%) contrast(87%);
        }
    }
}