:root[data-theme="light"] {
    --workzone-header-bg: #FFF;
    --workzone-block-a: #868686;
    --icon-star: invert(100%) sepia(0%) saturate(6439%) hue-rotate(297deg) brightness(86%) contrast(88%);
  }

:root[data-theme="dark"] {
    --workzone-header-bg: #262626;
    --workzone-block-a: #8B8B8B;
    --icon-star: invert(100%) sepia(3%) saturate(373%) hue-rotate(170deg) brightness(116%) contrast(81%);
}  

.plane-header {
    padding: 4px 40px 10px 40px;
    background-color: var(--workzone-header-bg);

    .icon-star {
        padding: 7px !important;
        line-height: 98%;
        
        &:hover {
            background-color: #3385FF;
        }

        img {
            filter: var(--icon-star);
        }
    }

    .workzones-list {
        border-bottom: 1px solid #363636;
        font-size: 15px;
        font-weight: 600;

        .workzone-block {
            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid #363636;
            font-weight: 400;

            &:first-child {
                padding-left: 0;
                border-left: none;
            }

            a {
                color: var(--workzone-block-a);
                text-decoration: none;
                padding: 0 10px 4px 10px;

                &.active {
                    color: #3385FF;
                    border-bottom: 2px solid #3385FF;
                    padding-bottom: 1px;
                    font-weight: 600
                }

                &:hover {
                    color: #3385FF;
                }
            }
        }
    }

    .workzone-title {
        font-size: 26px;
        font-weight: 600;
        color: #CCC;
    }
}

.plane-block {
    padding-left: 40px;
    padding-right: 40px;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: scroll;
    position: relative;
    height: calc(100vh - 150px);
}

.shadow-block{
    position: absolute;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, black -15%, transparent 90%);
    left: 0;
    bottom: 39px;
}