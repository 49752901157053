.profile-modal {
    .menu-list {
        border-bottom: 1px solid #363636;
        font-size: 15px;
        font-weight: 600;

        .menu-block {
            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid #363636;
            font-weight: 400;

            &:first-child {
                padding-left: 0;
                border-left: none;
            }

            .btn {
                color: var(--workzone-block-a);
                text-decoration: none;
                padding: 0 10px 4px 10px;
                border: 0;
                border-radius: 0;
                font-size: 15px;

                &.active {
                    color: #3385FF;
                    border-bottom: 2px solid #3385FF;
                    padding-bottom: 1px;
                    font-weight: 600;
                }
            }
        }
    }

    .modal-card-title {
        font-size: 24px;
        color: #CCC;
        font-weight: 600;
    }

    .profile-lk-statistic {
        background-color: #2F2F2F;
        border-radius: 10px;
        padding: 20px;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 106%;

        .statistic-item {
            padding-left: 20px;
            border-right: 1px solid #3C3C3C;

            &:last-child {
                border-right: none;
            }

            .modal-card-subtitle {
                color: #8B8B8B;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 106%; 
            }

            .rate-color {
                color: #FDA837;
            }
        }
    }

    .form-switch {
        .form-check-input {
            margin-top: 0;
            --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e');
        }

        &.form-check {
            min-height: 0;
        }
    }

    .show-in-catalog-block {
        background-color: #292427;
        border-radius: 0 0 10px 10px;
        padding: 10px;
    }

    .service-card {
        border: 1px solid #363636;

        .btn-block {
            background-color: #292427;

            .btn {
                background: #2F2F2F;
            }
        }

        .order-header-type-block{
            background-color: var(--order-header-type);
            border-bottom-right-radius: 10px;
            border-top-left-radius: 10px;
            font-size: 11px;
            line-height: 100%;
            margin-top: -3px;
            margin-left: -1px;
            vertical-align: text-top;

            .order-category {
                padding: 3px 8px;
                border-radius: 10px 0 10px 0;

                &.order-category-1 {
                    background-color: var(--order-header-category-bg-1);
                    color: var(--order-header-category-color-1);
                }
                &.order-category-2 {
                    background-color: var(--order-header-category-bg-2);
                    color: var(--order-header-category-color-2);
                }
                &.order-category-3 {
                    background-color: var(--order-header-category-bg-3);
                    color: var(--order-header-category-color-3);
                }
            }
            .order-type {
                padding: 3px 8px;
            }
        }

        .service-type-block {
            padding: 10px;
            padding-top: 0px;

            .service-type-name {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 106%;
                color: #8B8B8B;
            }
        }

        .hr {
            border: 1px solid #363636;
            opacity: 1;
            margin-left: 20px;
            margin-right: 20px;
        }

        .service-description {
            padding: 10px;
            font-size: 15px;
        }

        .service-price {
            padding: 10px;
            padding-top: 0px;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 105%;
        }
    }
}